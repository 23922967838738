import React, { Component } from 'react';

// import Header from '../components/Header';
import OldHeader from '../components/OldHeader';
import Footer from '../components/Footer';
import MapperControl from '../components/MapperControl';

// import sassVars from '../style/_common.scss';
// import * as globals from '../globals';
// import '../style/HomePage.scss';

class HomePage extends Component {
  render() {
    // fetch('http://ip-api.com/json').then(async resp => {
    // let val = await resp.json();
    // console.log(JSON.stringify(val, null, 2));
    // });

    // let svgProps = {
    //   cx: '6',
    //   cy: '6',
    //   r: '5',
    //   stroke: '#00000088',
    //   strokeWidth: '1',
    // };
    return (
      <div>
        <OldHeader />
        <div className="wide">
          <div className="HomePage">
            <div className="row HeaderRow">
              <div className="col">
                <h1>Geeks Who Drink Map!</h1>
                <p>
                  The map below displays the locations of all known Geeks Who
                  Drink trivia nights around the country. Are we missing any?
                  Let us know at team@geekswhodrinkmap.com.
                </p>
              </div>
            </div>
            <MapperControl />
          </div>

          <br />
          <br />
          <br />
        </div>
        <Footer />
      </div>
    );
  }
}

export default HomePage;
