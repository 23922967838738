import React, { Component } from 'react';
import { Spin } from 'antd';
// import Head from 'next/head';
import { Helmet } from 'react-helmet';

import * as structured from '../structured';
// import Header from '../components/Header';
import OldHeader from '../components/OldHeader';
// import Footer from '../components/Footer';
// import PeopleSection from '../components/PeopleSection';
// import BannerSection from '../components/BannerSection';
// import VideoSection from '../components/VideoSection';
import TopInfoSection from '../components/TopInfoSection';
// import UnverifiedSection from '../components/UnverifiedSection';
// import ContactSection from '../components/ContactSection';
// import PracticeInfoSection from '../components/PracticeInfoSection';
// import BenefitsSection from '../components/BenefitsSection';
// import PricingSection from '../components/PricingSection';
// import MapperSection from '../components/MapperSection';
// import LocationPhotosSection from '../components/LocationPhotosSection';
// import SendMessageSection from '../components/SendMessageSection';
// import EnrollmentSection from '../components/EnrollmentSection';
// import DPCSection from '../components/DPCSection';

import * as globals from '../globals';

// import '../style/PracticePage.scss';

class PracticePage extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, data: null };
  }

  componentDidMount = () => {
    let pid = this.props.pid || this.props.match.params.pid;
    console.log('Got pid: ' + pid);

    this.unsub = globals.db.child(`venues/${pid}`).on('value', snap => {
      if (!snap || !snap.exists()) {
        console.log('no practice found with id ' + pid);
        return;
      }
      let practiceData = snap.val();
      console.log(practiceData);
      this.setState({ data: practiceData, pid });
    });

    return;
    // this.unsub = globals.db.child(`practices/${pracKey}`).on('value', snap => {
    //   console.log('got snap');
    //   if (!snap || !snap.exists()) {
    //     // message.error('Error fetching data for practice.');
    //     return;
    //   }
    //   console.log('got data');
    //   this.setState({ data: snap.val() });
    // });
  };

  componentWillUnmount = () => {
    if (this.unsub) this.unsub();
  };

  render() {
    const { data: propData } = this.props;
    const { data: stateData } = this.state;

    const data = stateData || propData;

    // let hasProfiles = false;
    // for (let person of data.people) {
    //   if (person.image || person.bio) {
    //     hasProfiles = true;
    //   }
    // }
    console.log('rendering');

    // let content;
    if (this.state.loading || !data) {
      return (
        <div className="fullpage">
          <Spin />
        </div>
      );
    }

    return (
      <div className="PracticePage">
        <Helmet>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(structured.place(data), null, 2),
            }}
          />
          {structured.physicians(data).map(sd => {
            return (
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(sd, null, 2),
                }}
              />
            );
          })}
        </Helmet>
        <div className="body">
          <OldHeader />
          <TopInfoSection data={data} />
        </div>
      </div>
    );
  }
}

export default PracticePage;
