import { combineReducers } from 'redux';
import meta from './meta';
import mapperReducer, * as mapperDuck from './mapperDuck';

export const uidDuck = meta('uid', null, '');
export const userDataDuck = meta('userData', {}, {});
export const permissionsDuck = meta('permissions', null, {});
export const activePracticeDuck = meta('active_practice', null, null);
export const activeSidebarItemDuck = meta('active_sidebar_item', 'mapper');
export const drawerOpenDuck = meta('drawer_open', false, false);
export const loadingDuck = meta('loading', {}, {});
export { mapperDuck };

const rootReducer = combineReducers({
  uid: uidDuck.REDUCER,
  userData: userDataDuck.REDUCER,
  permissions: permissionsDuck.REDUCER,
  mapper: mapperReducer,
  activePractice: activePracticeDuck.REDUCER,
  activeSidebarItem: activeSidebarItemDuck.REDUCER,
  drawerOpen: drawerOpenDuck.REDUCER,
  loading: loadingDuck.REDUCER,
});

export default rootReducer;
