import React, { Component } from 'react';
import { Icon, Drawer } from 'antd';

// import * as globals from '../globals';
import logo from '../img/gwd.png';

// import '../style/Header.scss';
// import logo from '../static/img/gwd.png';

class App extends Component {
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const links = (
      <div className="row">
        <p>
          <a href="https://geekswhodrinkmap.com/">Geek Who Drink Mapper</a>
        </p>
      </div>
    );

    return (
      <div className="row Header">
        <a href={'/'} className="clickable">
          <img className="logo" src={logo} alt="Geeks Who Drink Mapper Logo" />
        </a>
        <div className="spacer" />
        <div className="showlarge showxlarge">{links}</div>
        <Icon
          style={{ color: 'white', fontSize: '28px' }}
          type="menu"
          theme="outlined"
          className="showsmall showmedium"
          onClick={this.showDrawer}
        />
        <Drawer
          title="Geeks Who Drink Map Menu"
          placement="right"
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          {links}
        </Drawer>
      </div>
    );
  }
}

export default App;
