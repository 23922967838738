import React, { Component } from 'react';
// import * as globals from '../globals';
import logo from '../img/gwd.png';
// import '../style/Footer.scss';

class Footer extends Component {
  render() {
    return (
      <div className="Footer wrap">
        <div />
        <div style={{ textAlign: 'center' }}>
          <p style={{ textAlign: 'center' }}>
            <small>
              Geeks Who Drink Map
              <br />
              Made with 🍺 in San Jose, CA.
              <br />
              No rights reserved.
            </small>
          </p>
        </div>
        <a href={'https://geekswhodrinkmap.com'} className="clickable">
          <img
            className="logo"
            src={logo}
            alt="Geek Who Drink logo"
            style={{ width: '100px' }}
          />
        </a>
      </div>
    );
  }
}

export default Footer;
