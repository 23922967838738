import React from 'react';

const InfoItem = ({ title, children }) => {
  return (
    <div className={'InfoItem wordbreak'}>
      <p style={{ lineHeight: '100%' }}>
        <small style={{ opacity: 0.7 }}>{title}</small>
      </p>
      <div style={{ marginTop: '0px' }}>{children}</div>
    </div>
  );
};

export default InfoItem;
