import React from 'react';
import { connect } from 'react-redux';
import { Modal, Spin } from 'antd';

import store from '../store';
import { loadingDuck } from '../ducks/root';
const Loading = props => {
  if (!props.loading) return null;
  return (
    <Modal
      key="modal"
      visible={props.loading.show}
      footer={null}
      closable={false}
      onCancel={() => {
        store.dispatch(loadingDuck.clear());
      }}
    >
      <div className={'centercol'}>
        <Spin />
        <h4>{props.loading.text}</h4>
      </div>
    </Modal>
  );
};

const mstp = state => ({
  loading: state.loading,
});
export default connect(mstp)(Loading);
