import * as globals from './globals';
import { specialties } from './data';

export const serialize = obj => {
  var str = [];
  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(
        encodeURIComponent(p) + '=' + encodeURIComponent(JSON.stringify(obj[p]))
      );
    }
  }
  return str.join('&');
};

export const randomString = (len = 10) => {
  var text = '';
  var possible = 'abcdefghijklmnopqrstuvwxyz';

  for (var i = 0; i < len; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

export const slugify = str => {
  return str.toLowerCase().replace(/[^a-z]+/g, '_');
};

export const upload = async (file, path, callback) => {
  console.log(file);
  console.log('starting upload...');
  var ref = globals.firebase
    .storage()
    .ref()
    .child(path);
  console.log(ref);
  let uploadTask = ref.put(file);

  uploadTask.on(
    globals.firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
    () => {},
    error => {
      console.log(error);
      callback(error);
      switch (error.code) {
        case 'storage/unauthorized':
          break;
        case 'storage/canceled':
          break;
        case 'storage/unknown':
          break;
        default:
          break;
      }
    },
    () => {
      // Upload completed successfully, now we can get the download URL
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        console.log('File available at', downloadURL);
        callback(null, downloadURL);
      });
    }
  );
};

export const addrString = addr => {
  if (!addr || Object.keys(addr).length === 0) return '';
  let addrComps = [
    addr.line1,
    addr.line2,
    addr.city,
    `${addr.region} ${addr.postal ? ' ' + addr.postal : ''}`,
  ];
  return addrComps
    .filter(x => x)
    .map(x => x.trim())
    .join(', ');
};

export const addrLink = addr => {
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    addrString(addr)
  )}`;
};

export const inout = obj => {
  let newobj = {};
  for (let key in obj) {
    let val = obj[key];
    newobj[val] = key;
  }
  return newobj;
};

export const objify = (arr, keyKey = 'value', valueKey = 'label') => {
  let obj = {};
  for (let item of arr) {
    obj[item[keyKey]] = item[valueKey];
  }
  return obj;
};

export const capitalize = lower => {
  if (!lower || !lower.length) return '';
  return lower.charAt(0).toUpperCase() + lower.substr(1);
};

export const httpify = addr => {
  if (addr.includes('http://') || addr.includes('https://')) return addr;
  return 'http://' + addr;
};

export const computePanelStatus = people => {
  let panelStatus;
  people = people || [];
  for (let person of people) {
    if (person.panelStatus === 'open') {
      panelStatus = 'open';
      break;
    }
    if (person.panelStatus === 'waitlist') {
      // overwrite but don't break
      panelStatus = 'waitlist';
    }
    if (person.panelStatus === 'full') {
      // only overwrite if panelStatus hasn't been set
      panelStatus = panelStatus || 'full';
    }
  }
  panelStatus = panelStatus || 'open';
  return panelStatus;
};

export const computeSpecialties = people => {
  let specIds = {};
  people = people || [];
  if (people.length === 0) {
    return 'Unknown.';
  }
  for (let person of people) {
    specIds = { ...specIds, [person.specialty]: true };
  }
  console.log('specIds: ' + JSON.stringify(specIds, null, 2));
  let specKeys = Object.keys(specIds);
  console.log('specKeys: ' + JSON.stringify(specKeys, null, 2));
  let specMap = objify(specialties, 'value', 'label');
  console.log('specMap: ' + JSON.stringify(specMap, null, 2));
  let specs = specKeys.map(x => specMap[x]);
  console.log('specs: ' + JSON.stringify(specs, null, 2));
  let specText =
    specs.length === 1
      ? specs[0]
      : specs.slice(0, -1).join(', ') + ' and ' + specs.slice(-1)[0];
  console.log('specText: ' + JSON.stringify(specText, null, 2));
  return specText;
};

export const exists = val => {
  if (val === undefined) return false;
  if (isNaN(val)) return false;
  if (val === null) return false;
  if (Array.isArray(val) && val.length === 0) return false;
  if (typeof val === 'object' && Object.keys(val).length === 0) return false;
  if (typeof val === 'string' && val.length === 0) return false;
  return true;
};
