import React from 'react';

const Flex = props => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: props.dir,
        width: props.wide ? '100%' : 'auto',
        height: props.tall ? '100%' : 'auto',
        justifyContent: props.justify || 'center',
        alignItems: props.align || 'center',
        flexWrap: props.wrap ? 'wrap' : 'no-wrap',
      }}
    >
      {props.children}
    </div>
  );
};

export const Row = props => {
  return <Flex {...{ ...props, dir: 'row' }}>{props.children}</Flex>;
};
export const Column = props => {
  return <Flex {...{ ...props, dir: 'column' }}>{props.children}</Flex>;
};

export default Flex;
