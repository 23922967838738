import React from 'react';
import { Spin } from 'antd';

import * as globals from '../globals';
import Mapper from './Mapper';
import PracticeDetails from './PracticeDetails';
import MapControls from './MapControls';

// import '../style/Mapper.scss';

class MapperControl extends React.Component {
  state = {
    refs: {},
    practices: null,
    activePractice: 17,
    bounds: null,
    google: false,
  };

  async componentDidMount() {
    this.cache = {};

    try {
      const snap = await globals.db.child('venues').once('value');
      let practices = snap.val();
      this.setState({ practices: practices });
    } catch (err) {
      console.log('error fetching practices');
      console.log(err);
    }
  }

  setGoogle = goog => {
    console.log('setting google');
    // if (this.state.google) return;
    this.setState({ google: goog });
    if (this.state.refs['map']) {
      let mapRef = this.state.refs['map'];
      mapRef.controls = [];
      mapRef.mapTypes = new goog.maps.MapTypeRegistry();
      mapRef.overLayMapTypes = [];
    }
  };
  setActivePractice = i => {
    this.setState({ activePractice: i });
  };

  setRef = name => ref => {
    if (this.state.refs[name] !== undefined) return;
    this.setState({
      refs: {
        ...this.state.refs,
        [name]: ref,
      },
    });
  };

  setBounds = () => {
    if (!this.state.refs.map) return;
    if (!this.state.refs.map.getBounds()) return;
    if (!this.state.refs.map.getCenter()) return;
    this.setState({
      bounds: this.state.refs.map.getBounds(),
      center: this.state.refs.map.getCenter(),
    });
  };

  onPlacesChanged = () => {
    if (!this.state.refs.search) {
      return;
    }

    const places = this.state.refs.search.getPlaces();
    if (!places || !places.length) {
      return;
    }

    const bounds = new this.state.google.maps.LatLngBounds();

    places.forEach(place => {
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    let nextCenter = (places[0] || {}).position || this.state.center;

    this.setState({
      center: nextCenter,
      bounds: bounds,
    });
    this.state.refs.map.fitBounds(bounds);
  };

  render() {
    let practicesInBounds = {};
    if (this.state.google && this.state.bounds && this.state.practices) {
      for (let pracId in this.state.practices) {
        let prac = this.state.practices[pracId];
        let loc;
        let key = `${prac.lat}_${prac.lng}`;
        if (this.cache[key]) {
          loc = this.cache[key];
        } else {
          let newLoc = new this.state.google.maps.LatLng({
            lat: prac.lat,
            lng: prac.lng,
          });
          this.cache[key] = newLoc;
          loc = newLoc;
        }

        if (this.state.bounds.contains(loc)) {
          practicesInBounds[pracId] = prac;
        }
      }
    }

    return (
      <div className="row wrap MapRow">
        <div className="col MapCol">
          <MapControls
            init={this.setRef('search')}
            onPlacesChanged={this.onPlacesChanged}
            bounds={this.state.bounds}
          />
          <div className="MapContainer">
            {this.state.practices == null ? (
              <div className="fullpage">
                <Spin />
              </div>
            ) : (
              <Mapper
                setGoogle={this.setGoogle}
                practices={this.state.practices}
                setActivePractice={this.setActivePractice}
                init={this.setRef('map')}
                setBounds={this.setBounds}
              />
            )}
          </div>
        </div>
        {this.props.hideDetails !== true && (
          <PracticeDetails
            id={this.state.activePractice}
            data={
              this.state.activePractice !== null && this.state.practices
                ? this.state.practices[this.state.activePractice]
                : null
            }
          />
        )}
      </div>
    );
  }
}
export default MapperControl;
