import React from 'react';
import { Button } from 'antd';
// import ReactTooltip from 'react-tooltip';

// import '../style/PracticeDetails.scss';

import IconItem from './IconItem';
// import TextInfoItem from './TextInfoItem';

import AddrLink from './AddrLink';
// import SocialIcons from './SocialIcons';
// import PeopleList from './PeopleList';
// import HoursList from './HoursList';
import Spacer from './Spacer';
// import PricingTable from './PricingTable';
// import InfoItem from './InfoItem';
// import TypeTag from './TypeTag';
// import { Row } from './Flex';

import * as util from '../util';
// import { panelIcons, panelTexts } from '../data.js';

const PracticeDetails = ({ data: p, id }) => {
  if (!p) {
    return (
      <div className={'PracticeDetails'}>
        <div>
          <small>
            <span style={{ color: '#00000080' }}>
              Click an icon to learn more about that trivia night.
            </span>
          </small>
        </div>
      </div>
    );
  }

  // let panelStatus = util.computePanelStatus(p.people);
  let address = {
    line1: p.venue,
    city: p.city,
    region: p.state,
    country: 'US',
  };
  // let addrStr = `${p.venue}, ${p.city}, ${p.state}`;

  const style = {
    margin: '0px 0px 2px px',
  };
  return (
    <div className={'PracticeDetails'}>
      <div>
        <h3 style={style}>{p.venue}</h3>
        <p style={style}>{`${p.city}, ${p.state}`}</p>
      </div>
      {false && (
        <Button
          className="cstm"
          type="primary"
          size={'default'}
          href={`/venue/${id}`}
          target="_blank"
        >
          View Full Profile
        </Button>
      )}
      <div>
        <IconItem icon={`clock-circle`}>
          <p>{p.day}</p>
          <Spacer size="xsmall" />
        </IconItem>

        <IconItem icon="shop">
          <p>{util.addrString(address)}</p>
          <Spacer size="xsmall" />
        </IconItem>
        <IconItem icon="environment">
          <AddrLink addr={util.addrString(address)} text="Get directions" />
          <Spacer size="xsmall" />
        </IconItem>
        <IconItem icon="link">
          <a target="_blank" rel="noopener noreferrer" href={p.link}>
            {p.link}
          </a>
          <Spacer size="xsmall" />
        </IconItem>
      </div>
    </div>
  );
};

export default PracticeDetails;
