import React from 'react';
import { StandaloneSearchBox } from 'react-google-maps/lib/components/places/StandaloneSearchBox';
// import '../style/MapControls.scss';
const MapControls = props => {
  if (!process.browser || !window.google) {
    return null;
  }

  return (
    <div className="MapControls">
      <StandaloneSearchBox
        ref={props.init}
        bounds={props.bounds}
        onPlacesChanged={() => {
          props.onPlacesChanged();
        }}
      >
        <input
          type="text"
          id="map-search"
          className="SearchBox"
          placeholder="City/zip code"
        />
      </StandaloneSearchBox>
    </div>
  );
};

export default MapControls;
