import React from 'react';

import { Row } from './Flex';
import Spacer from './Spacer';

export default function HoursTable(props) {
  const { hours } = props;
  if (!hours || !hours.length) {
    return <p>Unknown.</p>;
  }
  return (
    <div className="table">
      {hours.map(hour => {
        if (!hour) return null;
        return (
          <Row justify="space-between">
            <span style={{ textAlign: 'left' }}>{hour.days}</span>
            <Spacer size="small" />
            <b style={{ textAlign: 'right' }}>{hour.hours}</b>
          </Row>
        );
      })}
    </div>
  );
}
