import React from 'react';
import { compose, withProps } from 'recompose';
import { Spin } from 'antd';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from 'react-google-maps';

import sassVars from '../style/_common.scss';
import * as globals from '../globals';

class Mapper extends React.Component {
  state = {
    foundGoogle: false,
  };

  componentDidMount() {
    if (!process.browser) {
      return;
    }

    let intervalHandle = setInterval(() => {
      if (this.state.foundGoogle) return;
      if (window.google) {
        this.props.setGoogle(window.google);
        this.setState({ foundGoogle: true });
        clearInterval(intervalHandle);
      }
    }, 200);
  }

  render() {
    if (!this.state.foundGoogle) {
      return null;
    }

    const { practices } = this.props;
    if (practices.length === 0) {
      return null;
    }
    if (!window.google) return null;

    return (
      <GoogleMap
        ref={this.props.init}
        options={{
          mapTypeControl: false,
        }}
        defaultZoom={3}
        defaultCenter={{ lat: 39.8283, lng: -98.5795 }}
        onBoundsChanged={this.props.setBounds}
      >
        {this.props.children}
        {Object.keys(practices).map((pracId, i) => {
          let p = practices[pracId];
          if (!p.lat || !p.lng) return null;

          let colorMap = {
            pure: sassVars.greenColor,
            hybrid: sassVars.redColor,
            onsite: sassVars.blueColor,
            unknown: sassVars.yellowColor,
            concierge: sassVars.yellowColor,
          };
          let color = colorMap[p.kind] || sassVars.greenColor;
          if (p.onsite === true) color = colorMap.onsite;

          let circleIcon = {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 4,
            fillOpacity: 1,
            fillColor: color,
            strokeWeight: 1,
            strokeColor: '#00000088',
          };

          // let goldStar = {
          //   path:
          //     'M 125,5 155,90 245,90 175,145 200,230 125,180 50,230 75,145 5,90 95,90 z',
          //   fillColor: 'yellow',
          //   fillOpacity: 0.8,
          //   scale: 0.05,
          //   strokeWeight: 1,
          //   strokeColor: '#00000088',
          // };

          // let shopIcon = {
          //   path:
          //     'M882 272.1V144c0-17.7-14.3-32-32-32H174c-17.7 0-32 14.3-32 32v128.1c-16.7 1-30 14.9-30 31.9v131.7a177 177 0 0 0 14.4 70.4c4.3 10.2 9.6 19.8 15.6 28.9v345c0 17.6 14.3 32 32 32h676c17.7 0 32-14.3 32-32V535a175 175 0 0 0 15.6-28.9c9.5-22.3 14.4-46 14.4-70.4V304c0-17-13.3-30.9-30-31.9zM214 184h596v88H214v-88zm362 656.1H448V736h128v104.1zm234 0H640V704c0-17.7-14.3-32-32-32H416c-17.7 0-32 14.3-32 32v136.1H214V597.9c2.9 1.4 5.9 2.8 9 4 22.3 9.4 46 14.1 70.4 14.1s48-4.7 70.4-14.1c13.8-5.8 26.8-13.2 38.7-22.1.2-.1.4-.1.6 0a180.4 180.4 0 0 0 38.7 22.1c22.3 9.4 46 14.1 70.4 14.1 24.4 0 48-4.7 70.4-14.1 13.8-5.8 26.8-13.2 38.7-22.1.2-.1.4-.1.6 0a180.4 180.4 0 0 0 38.7 22.1c22.3 9.4 46 14.1 70.4 14.1 24.4 0 48-4.7 70.4-14.1 3-1.3 6-2.6 9-4v242.2zm30-404.4c0 59.8-49 108.3-109.3 108.3-40.8 0-76.4-22.1-95.2-54.9-2.9-5-8.1-8.1-13.9-8.1h-.6c-5.7 0-11 3.1-13.9 8.1A109.24 109.24 0 0 1 512 544c-40.7 0-76.2-22-95-54.7-3-5.1-8.4-8.3-14.3-8.3s-11.4 3.2-14.3 8.3a109.63 109.63 0 0 1-95.1 54.7C233 544 184 495.5 184 435.7v-91.2c0-.3.2-.5.5-.5h655c.3 0 .5.2.5.5v91.2z',
          //   fillColor: color,
          //   fillOpacity: 0.8,
          //   scale: 0.01,
          //   strokeWeight: 1,
          //   strokeColor: color,
          // };

          let icon = circleIcon; //Math.random() < 0.5 ? goldStar : circleIcon;

          return (
            <Marker
              key={i}
              position={{ lat: p.lat, lng: p.lng }}
              // icon={icon}
              icon={{
                // size: window.google.maps.Size('8px', '8px'),
                scaledSize: new window.google.maps.Size(10, 10),
                url: `https://mapper.dpcfrontier.com/img/green.png`,
              }}
              onClick={() => {
                this.props.setActivePractice(pracId);
              }}
            />
          );
        })}
      </GoogleMap>
    );
  }
}

export default compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${globals.MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: (
      <div className="fullpage">
        <Spin />
      </div>
    ),
    containerElement: <div className={'fill'} />,
    mapElement: <div className={'fill'} />,
  }),
  withScriptjs,
  withGoogleMap
)(Mapper);
