import React from 'react';

const AddrLink = ({ addr, text }) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        addr
      )}`}
    >
      {text}
    </a>
  );
};
export default AddrLink;
