import { specialties } from './data';

export const place = data => {
  let addr = data.address;
  console.log('addr');
  console.log(addr);
  let addrObj = {};
  if (addr) {
    addrObj = {
      '@type': 'PostalAddress',
      addressCountry: data.address.country,
      addressLocality: data.address.city,
      addressRegion: data.address.region,
      streetAddress: data.address.line1 + ', ' + data.address.line2,
      postalCode: data.address.postal,
    };
  }
  return {
    '@context': 'http://schema.org/',
    '@type': 'MedicalOrganization',
    address: addrObj,
    faxNumber: data.fax,
    telephone: data.phone,
    description: data.description,
    mainEntityOfPage: data.website,
    name: data.name,
    logo: data.logo,
    image: data.locationPhoto,
    email: data.emails ? data.emails.split(',') : '',
    contactPoints: {
      '@type': 'ContactPoint',
      email: data.emails ? data.emails.split(',') : '',
      faxNumber: data.fax,
      telephone: data.phone,
    },
    medicalSpecialty: { name: specialties[data.specialty] },
  };
};

export const physicians = data => {
  let phys = [];
  data.people = data.people || [];

  let addrObj = {};
  if (data.address) {
    addrObj = {
      '@type': 'PostalAddress',
      addressCountry: data.address.country,
      addressLocality: data.address.city,
      addressRegion: data.address.region,
      streetAddress: data.address.line1 + ', ' + data.address.line2,
      postalCode: data.address.postal,
    };
  }

  for (let person of data.people) {
    phys.push({
      '@context': 'http://schema.org/',
      '@type': 'Physician',
      address: addrObj,
      contactPoint: {
        '@type': 'ContactPoint',
        faxNumber: data.fax,
        telephone: data.phone,
      },
      image: {
        '@type': 'ImageObject',
        contentUrl: person.image,
      },
      description: person.bio,
      name: person.fn + ' ' + person.ln,
    });
  }
  return phys;
};
