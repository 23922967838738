import React from 'react';
import { Icon } from 'antd';
import { Row } from './Flex';
import Spacer from './Spacer';

const IconItem = ({ icon, children, iconProps }) => {
  return (
    <div className={'IconItem wordbreak'}>
      <Row justify="flex-start" align="flex-start">
        <Icon type={icon} {...iconProps} />
        <Spacer size="xsmall" />
        <div style={{ flex: 1 }}>{children}</div>
      </Row>
    </div>
  );
};

export default IconItem;
