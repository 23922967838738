import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

import googleMapsClient from '@google/maps';

export const MAPS_API_KEY = 'AIzaSyC6ey4TaIdrfOqMmhEuIAy3imrxiMKNHS4';
export const maps = googleMapsClient.createClient({
  key: MAPS_API_KEY,
  Promise: Promise,
});

// Initialize Firebase

var config = {
  apiKey: 'AIzaSyCH30Aqj0AkBQV_2ymP_-Ix6XfPUCUd6SI',
  authDomain: 'triviamap-bf949.firebaseapp.com',
  databaseURL: 'https://triviamap-bf949.firebaseio.com',
  projectId: 'triviamap-bf949',
  storageBucket: '',
  messagingSenderId: '108500770265',
};
firebase.initializeApp(config);
// firebase.initializeApp(config);

const isLocal =
  typeof window !== 'undefined' &&
  window !== undefined &&
  window.location !== undefined &&
  window.location.href.includes('localhost');

console.log('IS LOCAL: ' + isLocal);

export { firebase };
export const db = firebase.database().ref();
// db.settings({ timestampsInSnapshots: true });
export const auth = firebase.auth();
