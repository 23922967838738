import React from 'react';
import InfoItem from './InfoItem';

const TextInfoItem = ({ title, content }) => {
  if (!content) return null;
  return (
    <InfoItem title={title}>
      <p>{content}</p>
    </InfoItem>
  );
};

export default TextInfoItem;
