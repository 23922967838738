import React, { Component } from 'react';
import { Button } from 'antd';

import Spacer from './Spacer';
import IconItem from './IconItem';
import InfoItem from './InfoItem';
import TextInfoItem from './TextInfoItem';
import HoursTable from './HoursTable';

import * as util from '../util';
import { panelIcons, panelTexts } from '../data';

export default class BannerSection extends Component {
  render() {
    const { data } = this.props;

    let panelStatus = util.computePanelStatus(data.people);
    console.log(panelIcons[panelStatus]);
    console.log(panelTexts[panelStatus]);

    let cityDesc = '';
    if (data.address && data.address.city && data.address.region) {
      cityDesc = `Direct Primary Care in ${data.address.city}, ${
        data.address.region
      }`;
    }
    return (
      <div className="section">
        <div className="sectionitem">
          {data.logo && (
            <img
              src={data.logo}
              alt={`${data.name} Logo`}
              style={{ maxWidth: '200px' }}
            />
          )}
          <h1 style={{ fontSize: '1.5rem' }}>{data.name}</h1>
          {cityDesc && (
            <h5>{`Direct Primary Care in ${data.address.city}, ${
              data.address.region
            }`}</h5>
          )}

          <Spacer size="medium" />

          <div className="leftrow wrap">
            {data.enrollment_code && [
              <Button
                href="#enrollment"
                className="cstm clickable"
                type="primary"
                style={{ marginBottom: '5px' }}
              >
                Enroll now
              </Button>,
              <Spacer size="xsmall" />,
            ]}
            {data.enrollment_url && [
              <Button
                href={data.enrollment_url}
                type="primary"
                target="_blank"
                className="cstm clickable"
              >
                Enroll now
              </Button>,
              <Spacer size="xsmall" />,
            ]}
            <div className="row">
              {data.phone && (
                <Button
                  href={`tel:${data.phone}`}
                  icon="phone"
                  target="_blank"
                  className="clickable cstm"
                >
                  Call
                </Button>
              )}

              {data.phone && <Spacer size="xsmall" />}

              {data.address && (
                <Button
                  href={util.addrLink(data.address)}
                  target="_blank"
                  className="clickable cstm"
                >
                  Get directions
                </Button>
              )}
            </div>
          </div>

          {data.description && data.description.trim() && (
            <p style={{ marginTop: '20px' }}>{data.description}</p>
          )}
        </div>
        <Spacer size="medium" />
        <div
          className="sectionitem"
          style={{
            // border: `1px solid ${sassVars.blueColor || '#1585ff80'}`,
            boxShadow: '0px 1px 3px #00000080',
            borderRadius: '8px',
            padding: '10px 14px',
            height: 'auto',
          }}
        >
          <div className="infobox">
            <IconItem icon={panelIcons[panelStatus]}>
              <p>{panelTexts[panelStatus]}</p>
              <Spacer size="xsmall" />
            </IconItem>
            {data.panelStatus === 'open' && [
              <IconItem icon="check-circle">Accepting new patients!</IconItem>,
              <Spacer size="xsmall" />,
            ]}

            {data.address && [
              <IconItem icon="shop">{util.addrString(data.address)}</IconItem>,
              <Spacer size="xsmall" />,
            ]}

            {data.website && [
              <IconItem icon="link">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={util.httpify(data.website)}
                >
                  {util.httpify(data.website)}
                </a>
              </IconItem>,
              <Spacer size="xsmall" />,
            ]}

            {data.phone && [
              <IconItem icon="phone">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`tel:${data.phone}`}
                >
                  {data.phone}
                </a>
              </IconItem>,
              <Spacer size="xsmall" />,
            ]}
            {data.fax && [
              <IconItem icon="printer">{data.fax}</IconItem>,
              <Spacer size="xsmall" />,
            ]}

            <TextInfoItem title="Open since" content={data.openDate} />
            <TextInfoItem
              title="Specialty"
              content={
                util.computeSpecialties(data.people) || 'Family Medicine'
              }
            />
            <TextInfoItem title="Ages accepted" content={data.acceptedAges} />

            <InfoItem title="Hours">
              <HoursTable hours={data.hours} />
            </InfoItem>
          </div>
        </div>
      </div>
    );
  }
}
